import { watch, reactive } from "vue";
import { NotificationMode, NotificationSettings } from "@dview/shared/models";
import { useUserPreferences } from "@dview/logic";

const state = reactive<NotificationSettingsState>({
    mode: true,
    newDeviations: true,
    newHocRatings: true
})

export function useNotificationSettings() {
    const { setNotificationSettings, preferences } = useUserPreferences()

    function init() {
        const { mode, newDeviations, newHocRatings } = mapToNotificationSettings(preferences.notifications)
        state.mode = mode
        state.newDeviations = newDeviations
        state.newHocRatings = newHocRatings
    }

    function mapToNotificationSettings(data: NotificationSettings): NotificationSettingsState {
        const { mode, newDeviations, newHocRatings } = data
        return {
            mode: Boolean(mode),
            newDeviations: newDeviations,
            newHocRatings: newHocRatings
        }
    }

    async function save() {
        const { mode, newDeviations, newHocRatings} = state
        const toggledNotifications = {
            mode: mode ? NotificationMode.Daily : NotificationMode.None,
            newDeviations: newDeviations,
            newHocRatings: newHocRatings
        }
        await setNotificationSettings(toggledNotifications)
    }

    watch(() => state.mode, mode => {
        notificationContentSwitchMimicModeSwitch(mode, state)
    })

    watch(() => state.newDeviations, newDeviations => {
        !state.mode && notificationContentSwitchMimicModeSwitch(state.mode, state)

        if (!newDeviations && !state.newHocRatings) {
            state.mode = false
        }
    })

    watch(() => state.newHocRatings, newHocRatings => {
        !state.mode && notificationContentSwitchMimicModeSwitch(state.mode, state)

        if (!state.newDeviations && !newHocRatings) {
            state.mode = false
        }
    })

    function clear() {
        state.mode = false
        state.newDeviations = false
        state.newHocRatings = false
    }

    init()

    return {
        notificationsModel: state,
        save,
        clear
    }
}

function notificationContentSwitchMimicModeSwitch(mode: boolean, state: NotificationSettingsState) {
    state.newDeviations = mode
    state.newHocRatings = mode
}

interface NotificationSettingsState {
    mode: boolean
    newDeviations: boolean
    newHocRatings: boolean
}
