
import { defineComponent, ref, onMounted, watch } from 'vue'
import { warningOutline, closeOutline } from 'ionicons/icons'

export default defineComponent({
    name: 'Modal',
    props: {
        title: { type: String, default: 'Modal title' },
        showCloseButton: { type: Boolean, default: true },
        isModalOpen: { type: Boolean, required: true },
        ctaButtonText: { type: String, default: 'Follow' },
        showCtaButton: { type: Boolean, default: false }
    },
    setup(props, { emit }) {
        const modalContentContainer = ref()

        function closeModal() {
            emit('close-modal')
        }

        function ctaButtonAction() {
            emit('cta-button-action')
        }

        // Inspiration for programmatically setting height dynamically of modal, might implement later
        /*watch([() => props.isModalOpen, modalContentContainer], async ([isOpen, container]) => {
            if (!isOpen || !container) return

            console.log(isOpen, container)

            const element = await waitForElement(() => document.querySelector('.dview-modal')) as HTMLElement
            console.log(element)

            if (!element || !modalContentContainer.value) {
                console.log('meeeeeeeeeeeh')
                return
            }

            console.log(modalContentContainer.value.clientHeight)
            console.log(modalContentContainer.value.scrollHeight)

            element.style.setProperty('--height', modalContentContainer.value.clientHeight + 'px')
        })*/

        return {
            closeModal,
            ctaButtonAction,
            warningOutline,
            closeOutline,
            modalContentContainer
        }
    }
})

// Inspiration for helpful function we might need at later refactor
/*async function waitForElement(retrieveElementFunc: Function, timeout = 2000) {
    return new Promise(resolve => {
        const timeoutHandle = setTimeout(() => {
            clearInterval(retryIntervalHandle)
            resolve(null)
        }, timeout)

        let retries = 0
        const retryIntervalHandle = setInterval(() => {
            const element = retrieveElementFunc()
            console.log('retries', retries++)
            if (element) {
                clearTimeout(timeoutHandle)
                clearInterval(retryIntervalHandle)
                resolve(element)
            }
        }, 1)
    })
}*/
