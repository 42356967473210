
import { computed, defineComponent, PropType } from 'vue'
import { warningOutline, alertCircleOutline, arrowForwardOutline, closeOutline } from 'ionicons/icons'
import { information } from '@dview/shared/icons'

export default defineComponent({
    inheritAttrs: false,
    props: {
        type: {
            type: String as PropType<MessageBannerType>,
            required: true,
            validator: (propValue: any) => {
                return ['error', 'warning', 'info'].indexOf(propValue) !== -1
            },
        },
        message: {
            type: String,
            required: true,
        },
        bannerActionType: {
            type: String as PropType<BannerActionType>,
            required: false
        },
        bannerActionText: {
            type: String,
            required: false
        }
    },
    setup(props, { attrs, emit }) {
        const messageIcon = computed(() => {
            if (props.type === 'error') {
                return alertCircleOutline
            } else if (props.type === 'warning') {
                return warningOutline
            } else {
                return information
            }
        })

        // detect if developer is using the component with an emit action
        const hasBannerAction = Boolean(attrs['onBannerAction']) && props.bannerActionType != null

        const bannerActionClass = hasBannerAction ? 'drop-shadow' : ''
        const colorClass = computed(() => props.type)

        function onBannerActionClicked() {
            emit('banner-action')
        }

        return {
          messageIcon,
          hasBannerAction,
          bannerActionClass,
          colorClass,
          onBannerActionClicked,
          actionNavigateIcon: arrowForwardOutline,
          actionDismissIcon: closeOutline
        }
    },
})

type MessageBannerType = 'error' | 'warning' | 'info'
type BannerActionType = 'dismiss' | 'navigate' | 'text';
