
import { debounce } from 'lodash'
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useAppInsights } from '@dview/shared/util'
import { useNative, usePushNotification, AppleSystemAppSchemes, NativePermission } from '@dview/logic'
import { useNotificationSettings } from './use-notification-settings'
import { Capacitor } from '@capacitor/core'
import DViewMessageBanner from '@dview/shared/components/DViewMessageBanner.vue'
import DViewModal from '@dview/shared/components/DViewModal.vue'

export default defineComponent({
    components: {
        DViewModal,
        DViewMessageBanner
    },
    setup() {
        const { trackEvent } = useAppInsights()
        const { save: saveSettings, clear: clearNotificationSettings, notificationsModel } = useNotificationSettings()
        const { hasAllowedPermission, onAppResume, canOpenNativeApp, openNativeApp } = useNative()
        const { registerDevice, unregisterDevice } = usePushNotification()
        const permission = ref(false)
        const modalOpen = ref(false)
        const iOSAppSettingsAvailable = ref(false)
        const showPermissionWarning = ref(false)

        setupPushPermissionCheck()

        const toggleNotifications = debounce(async () => {
            if (Capacitor.isNativePlatform()) {
                const permissionAllowed = await handlePushRegistration()

                // user tried to enable notifications, but rejected permissions, or failed to register device,
                // in that case clear notifications settings (disable notifications in UI and backend)
                if (!permissionAllowed) {
                    clearNotificationSettings()
                }

                await showHidePermissionWarning()
            }

            saveSettings()
        }, 100)

        const autoSaveSettings = debounce(async () => {
            trackEvent({
                name: 'Notifications updated in settings',
                properties: { 'notifications': notificationsModel }
            })

            saveSettings()
        }, 100)

        function trackNotificationTypeChange(notificationType: NotificationType) {
            const formattedNotificationType = notificationType.replace(/\s+/g, '-').toLowerCase()
            trackEvent({
                name: `Notifications for ${notificationType} have been updated`,
                properties: {
                    [`notif.-${formattedNotificationType}`]:
                        notificationType === 'Open deviations' ? notificationsModel.newDeviations : notificationsModel.newHocRatings
                }
            })
        }

        async function showHidePermissionWarning() {
            if (!Capacitor.isNativePlatform()) {
                return
            }

            permission.value = await hasAllowedPermission(NativePermission.PushNotifications)

            if (!permission.value) {
                showPermissionWarning.value = true
                iOSAppSettingsAvailable.value = await canOpenNativeApp(AppleSystemAppSchemes.AppSettings)
            } else {
                showPermissionWarning.value = false
                iOSAppSettingsAvailable.value = false
                modalOpen.value = false
            }
        }

        async function setupPushPermissionCheck() {
            if (!Capacitor.isNativePlatform()) {
                return
            }

            let clearAppResumeListener = () => {}

            onMounted(() => {
                showHidePermissionWarning()

                clearAppResumeListener = onAppResume(async () => {
                    const permission = await hasAllowedPermission(NativePermission.PushNotifications)

                    // for now, only attempt to automatically re-register device on return from iOS app settings
                    // don't try to unregister at this point, since notifications could still be toggled, and that
                    // would cause backend to start sending sms instead. 
                    //
                    // TODO: determine how to fully handle this case in a future refactor
                    if (permission && notificationsModel.mode) {
                        handlePushRegistration()
                    }

                    showHidePermissionWarning()
                })

            })

            onUnmounted(() => {
                clearAppResumeListener()
            })
        }

        async function handlePushRegistration() {
            if (notificationsModel.mode) {
                await registerDevice()
            } else {
                await unregisterDevice()
            }

            return await hasAllowedPermission(NativePermission.PushNotifications)
        }

        function openIOSAppSettingsForDView() {
            openNativeApp(AppleSystemAppSchemes.AppSettings)
        }

        return {
            showPermissionWarning,
            AppleSystemAppSchemes,
            iOSAppSettingsAvailable,
            notificationsModel,
            autoSaveSettings,
            toggleNotifications,
            openIOSAppSettingsForDView,
            trackNotificationTypeChange,
            modalOpen,
            permission,
        }
    }
})

type NotificationType = 'Open deviations' | 'HoC ratings'

