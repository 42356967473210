import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-553d6422"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message text-body-active" }
const _hoisted_2 = {
  key: 0,
  class: "action-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["message-banner", [ _ctx.bannerActionClass, _ctx.colorClass ]])
  }, [
    _createVNode(_component_ion_icon, {
      class: "icon-message-type",
      icon: _ctx.messageIcon
    }, null, 8, ["icon"]),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.message), 1),
    (_ctx.hasBannerAction)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "banner-action-area",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBannerActionClicked && _ctx.onBannerActionClicked(...args)))
        }, [
          (_ctx.bannerActionType === 'text')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.bannerActionText), 1))
            : _createCommentVNode("", true),
          (_ctx.bannerActionType === 'dismiss')
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 1,
                class: "icon-call-to-action",
                icon: _ctx.actionDismissIcon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (_ctx.bannerActionType === 'navigate')
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 2,
                class: "icon-call-to-action",
                icon: _ctx.actionNavigateIcon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}